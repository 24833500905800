import React from "react";
import { graphql, StaticQuery } from "gatsby";
import TimetableColumn from "./TimetableColumn";
import TextDefault from "../Text/TextDefault";

import { TimetableStyled, ColumnsWrapStyled } from "./TimetableStyled";

function Timetable({
  pageBuilder,
  data: {
    craft: { entries }
  }
}) {
  const { column, timetableHeadline, timetableText } = pageBuilder;
  const columns = column;

  return (
    <TimetableStyled columns={columns}>
      {timetableHeadline && timetableText && timetableText.content && (
        <TextDefault
          text={timetableText.content}
          headline={timetableHeadline}
          headlineTag="h2"
          layout="center"
          buttons={[]}
        />
      )}
      <ColumnsWrapStyled>
        {columns.map((column, idx) => {
          if (column) {
            return (
              <TimetableColumn
                globalCourses={entries}
                key={`column-${column.id}-idx_${idx}`}
                columnsCount={columns.length}
                column={column}
              />
            );
          }
          return null;
        })}
      </ColumnsWrapStyled>
    </TimetableStyled>
  );
}
// get TimeTable specific data
export default props => (
  <StaticQuery
    query={graphql`
      query {
        craft {
          entries(section: classes) {
            ... on Craft_Classes {
              title
              courseDescription {
                content
              }
            }
          }
        }
      }
    `}
    render={data => <Timetable data={data} {...props} />}
  />
);
export const query = graphql`
  fragment PageBuilderTimetableQuery on Craft_PageBuilderTimetable {
    timetableHeadline
    timetableText {
      content
    }
    column {
      id
      columnTitle
      classes {
        ... on Craft_ClassesClass {
          id
          timeStart
          timeEnd
          classDescription {
            content
          }
          class {
            id
            ... on Craft_Classes {
              title
              classImage {
                id
                url
              }
              courseDescription {
                content
              }
            }
          }
        }
      }
    }
  }
`;
