import React, { useState } from "react";

import { useMouseOver } from "utils/hooks";
import Redactor from "components/Redactor";
import {
  ColumnStyled,
  ColumnTitleStyled,
  AccordionHandleStyled,
  ClassesWrapperStyled,
  ClassStyled,
  ClassTimeStyled,
  ClassTitleStyled
} from "./TimetableStyled";
import Tooltip from "./Tooltip";

function isMobile() {
  const isClient = typeof window === "object";
  if (isClient) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  return false;
}

function TableClassBlock({ block, globalCourses }) {
  const [hoverEvent, bindHoverEvent] = useMouseOver();
  const onmobile = isMobile();
  const classBoundingRect =
    hoverEvent.target && hoverEvent.target.getBoundingClientRect();

  const currentCourseTitle = block && block.class[0] && block.class[0].title;
  // get section-course with the same name as current course
  const [globalCourse] = globalCourses.filter(
    course => course.title === currentCourseTitle
  );
  // if current class has own description, take it, else take section-course description
  const currentClassDescription =
    (block.classDescription && block.classDescription.content) ||
    (globalCourse &&
      globalCourse.courseDescription &&
      globalCourse.courseDescription.content);

  const getLeftPos = boundingRect => {
    const pos = boundingRect && boundingRect.left - 50;
    return pos > 0 ? pos : 0;
  };

  return (
    <ClassStyled {...bindHoverEvent}>
      {block.class[0] && (
        <>
          {!onmobile && hoverEvent.hovered && currentClassDescription ? (
            <Tooltip
              top={classBoundingRect && classBoundingRect.top + 90}
              left={getLeftPos(classBoundingRect)}
            >
              <Redactor content={currentClassDescription} parseType="content" />
            </Tooltip>
          ) : null}
          <ClassTimeStyled>
            {block.timeStart} - {block.timeEnd}
          </ClassTimeStyled>
          {block.class[0].classImage[0] ? (
            <img
              style={{ maxHeight: 50, maxWidth: 75 }}
              className="image"
              alt=""
              src={block.class[0].classImage[0].url}
            />
          ) : (
            <ClassTitleStyled>{block.class[0].title}</ClassTitleStyled>
          )}
        </>
      )}
    </ClassStyled>
  );
}

export default function TimetableColumn(props) {
  const [columnOpen, setColumnOpen] = useState(false);
  const { columnsCount, column, globalCourses } = props;

  function handleAccordionClick() {
    setColumnOpen(!columnOpen);
  }

  return (
    <ColumnStyled columnsCount={columnsCount} columnOpen={columnOpen}>
      {column && column.columnTitle && (
        <ColumnTitleStyled
          onClick={handleAccordionClick}
          columnOpen={columnOpen}
        >
          {column.columnTitle}
          <AccordionHandleStyled>
            {columnOpen ? "-" : "+"}
          </AccordionHandleStyled>
        </ColumnTitleStyled>
      )}

      <ClassesWrapperStyled columnOpen={columnOpen}>
        {column &&
          column.classes[0] &&
          column.classes.map(block => {
            return (
              <TableClassBlock
                globalCourses={globalCourses}
                block={{ ...block }}
                key={`class-${block.id}`}
              />
            );
          })}
      </ClassesWrapperStyled>
    </ColumnStyled>
  );
}
