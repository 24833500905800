import React, { useRef } from 'react';
import { styled } from 'utils/emotion';

const TooltipStyled = styled('div')(
  {
    backgroundColor: 'white',
    borderRadius: '5px',
    position: 'fixed',
    color: 'black',
    maxWidth: '500px',
    padding: '25px',
    zIndex: '100',
    display: 'inline-block',
    'div': {
      color: 'black',
    },      
    '& p': {
      color: 'black',
      fontSize: '.9rem',
      padding: 0,
      margin: '5px',
      lineHeight: '1.4',
    },
  },
  props => ({
    top: `${props.top}px`,
    left: `${props.left}px`,
  }),
);
const Tooltip = ({ top, left, children }) => {
  return (
    <TooltipStyled top={top} left={left}>
      <div>{children}</div>
    </TooltipStyled>
  );
};
export default Tooltip;
