import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

export const TimetableStyled = styled("div")({
  background: "linear-gradient(135deg, #0a2b2b 0%,#111d29 100%)",
  padding: "50px 0px 50px",
  zIndex: 1,
  [media("tabletFluid")]: {
    padding: "70px 0px 70px",
  },
});

export const ColumnsWrapStyled = styled("div")({
  maxWidth: 1200,
  margin: "0 auto",
  padding: "0px 50px",
  marginTop: 0,
  [media("tabletFluid")]: {
    marginTop: 50,
    padding: 0,
  },
});

export const ColumnStyled = styled("div")((props) => ({
  width: "100%",
  display: "inline-block",
  verticalAlign: "top",
  marginBottom: props.columnOpen ? 50 : 0,
  [media("tabletFluid")]: {
    padding: 10,
    width: `${100 / props.columnsCount}%`,
  },
}));

export const ColumnTitleStyled = styled("span")((props) => ({
  display: "block",
  textAlign: "center",
  textTransform: "uppercase",
  background: "#061C1F",
  padding: 20,
  fontWeight: 700,
  letterSpacing: "0.05em",
  fontSize: "12px",
  marginBottom: 30,
  position: "relative",
  color: props.columnOpen ? props.theme.colorTertiary : "white",
  [media("tabletFluid")]: {
    color: "white",
    marginBottom: 50,
  },
}));

export const AccordionHandleStyled = styled("span")({
  display: "inline-block",
  width: 30,
  textAlign: "center",
  position: "absolute",
  right: "10px",
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "26px",
  fontWeight: "700",
  [media("tabletFluid")]: {
    display: "none",
  },
});

export const ClassesWrapperStyled = styled("div")((props) => ({
  display: props.columnOpen ? "block" : "none",
  [media("tabletFluid")]: {
    display: "block",
  },
}));

export const ClassStyled = styled("div")({
  background: "#0C2F35",
  padding: 20,
  display: "block",
  marginBottom: 12,
  [media("tabletFluid")]: {
    marginBottom: 20,
  },
});

export const ClassTimeStyled = styled("span")({
  width: "100%",
  display: "block",
  fontSize: "11px",
  fontFamily: "Open Sans,sans-serif",
  marginBottom: 20,
});

export const ClassTitleStyled = styled("span")(
  {
    width: "100%",
    display: "block",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat, sans-serif",
    textTransform: "uppercase",
  },
  (props) => ({
    [media(0, 890)]: {
      fontSize: "12px",
    },
    color: props.theme.colorTertiary,
  })
);
